import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Tableloader from "./Tableloader";
import { FaSort } from "react-icons/fa";
import PropTypes from "prop-types";

const SortableTable = ({
  loading,
  tableData,
  columns = [
    { key: "requestId", label: "Request ID" },
    { key: "category", label: "Category" },
    { key: "notes", label: "Request Notes" },
    { key: "date", label: "Service Date" },
    { key: "value", label: "Service Value" },
  ],
}) => {
  const [data, setData] = useState(tableData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const sortData = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      // Handle numeric strings like "$123"
      const isNumericString = (value) =>
        typeof value === "string" && value.startsWith("$");

      if (isNumericString(aValue) && isNumericString(bValue)) {
        const aNumber = parseFloat(aValue.replace(/[^0-9.-]+/g, ""));
        const bNumber = parseFloat(bValue.replace(/[^0-9.-]+/g, ""));
        return direction === "asc" ? aNumber - bNumber : bNumber - aNumber;
      }

      // Handle numeric values
      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Handle Date objects
      if (
        Object.prototype.toString.call(aValue) === "[object Date]" &&
        Object.prototype.toString.call(bValue) === "[object Date]"
      ) {
        return direction === "asc"
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // Fallback to string comparison
      return direction === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  return (
    <div className="relative overflow-x-auto h-[450px] w-full shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-light-text-secondary sticky top-0 text-[12px] bg-light-background-neww">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className="px-6 py-5 cursor-pointer"
                onClick={() => sortData(column.key)}
              >
                <div className="flex gap-1 items-center">
                  {column.label}
                  <FaSort className="h-[10px] w-[10px]" />
                </div>
              </th>
            ))}
            <th className="px-6 py-3">Request Action</th>
          </tr>
        </thead>
        <tbody className="overflow-scroll">
          {loading ? (
            <Tableloader length={columns.length} />
          ) : data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index} className="bg-white border-b text-[13px]">
                {columns.map((column) => (
                  <td key={column.key} className="px-6 py-4">
                    {column.key === "serviceDate"
                      ? format(item[column.key], "dd/MM/yyyy")
                      : item[column.key]}
                  </td>
                ))}
                <td className="px-6 py-4">
                  <Link className="font-medium text-blue-600 hover:underline">
                    Close Request
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length + 1}
                className="px-6 py-4 text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SortableTable;

SortableTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  columns: PropTypes.array,
};
