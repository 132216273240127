import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { API_URL } from "../constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export function useLogin() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${API_URL}create/admin/login`, values);
      const { data, type, message } = response.data;

      if (type === "error") {
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
        setIsSubmitting(false);
      }

      if (data) {
        navigate("/authenticator", {
          state: {
            adminId: data.adminId,
            email: data.email,
          },
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      enqueueSnackbar("Login failed. Please try again.", { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });

  return {
    initialValues,
    onSubmit,
    setIsSubmitting,
    isSubmitting,
    validationSchema,
  };
}
