import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { CustomButton, CustomTextField } from "../../components";
import { useNavigate } from "react-router-dom";

export function ForgotPassword() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    email: "",
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${API_URL}create/admin/resend-otp`, {
        email: values.email,
      });
      const { type, message } = response.data;

      if (type === "error") {
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
        setIsSubmitting(false);
      }

      if (type === "success") {
        enqueueSnackbar(message, {
          variant: type,
        });
        navigate("/reset-password", { state: { email: values.email } });
      }
    } catch (error) {
      console.error("Error during OTP resend:", error);
      enqueueSnackbar("Failed to resend OTP. Please try again.", {
        variant: "error",
      });
      setIsSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
  });

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box sx={{ position: "absolute", right: "10px", top: 0 }}>
        <img style={{ width: "120px" }} src={logo} alt="Logo" />
      </Box>
      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 500,
              mb: 3,
              textAlign: "left",
            }}
          >
            Forgot Password!
          </Typography>
          <form onSubmit={handleSubmit}>
            <CustomTextField
              name="email"
              placeholder="Enter Your Email"
              label="Email"
              id="email"
              type="text"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <Typography
              onClick={() => navigate("/login")}
              sx={{
                cursor: "pointer",
                fontWeight: 500,
                textAlign: "right",
                mb: 2,
              }}
              variant="body2"
              color="error"
            >
              Back to login
            </Typography>
            <CustomButton type="submit" disabled={isSubmitting}>
              Forgot Password
            </CustomButton>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        sx={{
          display: { xs: "none", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={login} alt="Login" style={{ width: "70%" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
