import React, { useState } from "react";
import { Header, Sidebar } from "../components";
import { Avatar } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import SortableTable from "../components/SortableTable";
import ReactVirtualizedTable from "../components/DataTable/DataTable";
const data = [
  {
    requestId: "REQ001",
    category: "Maintenance",
    notes: "AC repair and servicing.",
    date: "2025-01-10",
    value: "$120",
  },
  {
    requestId: "REQ002",
    category: "Cleaning",
    notes: "Deep cleaning for office premises.",
    date: "2025-01-12",
    value: "$300",
  },
  {
    requestId: "REQ003",
    category: "Plumbing",
    notes: "Fix leakage in kitchen pipes.",
    date: "2025-01-14",
    value: "$80",
  },
  {
    requestId: "REQ004",
    category: "Electrical",
    notes: "Install new light fixtures.",
    date: "2025-01-15",
    value: "$200",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
  {
    requestId: "REQ005",
    category: "Landscaping",
    notes: "Trim hedges and mow the lawn.",
    date: "2025-01-17",
    value: "$150",
  },
];

export function Home() {
  const [loading, setloading] = useState(false);
  return (
    <div className="p-5 px-8 flex gap-8">
      <div className="flex  flex-col w-full gap-3">
        <p className="text-3xl font-semibold">Home</p>
        {/* <SortableTable loading={loading} tableData={data} /> */}
        <ReactVirtualizedTable />
      </div>
    </div>
  );
}
