import React from "react";
import { Button } from "@mui/material";

export const CustomButton = ({
  variant = "contained", // Default to 'contained' if no variant is passed
  onClick,
  children,
  type,
  disabled,
  ...rest
}) => {
  return (
    <Button
      fullWidth
      variant={variant}
      type={type}
      disabled={disabled}
      sx={{
        borderRadius: "8px",
        backgroundColor: disabled ? "#B0BEC5" : "#02B2D6",
        color: "#fff",
        fontSize: "16px",
        padding: "8px 150px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: disabled ? "#B0BEC5" : "#02B2D6",
        },
      }}
      onClick={onClick}
      {...rest} // Spread other props if needed
    >
      {children} {/* To render the text inside the button */}
    </Button>
  );
};
