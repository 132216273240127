import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../constants";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";

export function useResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    otp: "",
    newPassword: "",
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${API_URL}update/admin/reset-password`,
        {
          email,
          otp: values.otp,
          newPassword: values.newPassword,
        }
      );
      const { type, message } = response.data;

      if (type === "error") {
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
        setIsSubmitting(false);
      }

      if (type === "success") {
        enqueueSnackbar(message, {
          variant: type,
        });
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      enqueueSnackbar("Password reset failed. Please try again.", {
        variant: "error",
      });
      setIsSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Required!"),
    newPassword: Yup.string().required("Required!"),
  });

  return { validationSchema, onSubmit, isSubmitting, initialValues };
}
