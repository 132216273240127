import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Footer, Header, Sidebar } from "../components";
import {
  Home,
  LoginPage,
  ComingSoon,
  ForgotPassword,
  FASetup,
  LogoutPage,
  ResetPassword,
  Requester,
} from "../pages";
import { Box } from "@mui/material";
import NewSidebar from "../components/NewSidebar";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route for Login Page */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <ComingSoon />
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/authenticator"
          element={
            <PublicRoute>
              <FASetup />
            </PublicRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <div className="flex gap-2">
                <div>
                  {/* <Sidebar /> */}
                  <NewSidebar />
                </div>

                <div className="flex w-full flex-col h-full">
                  <Header />
                  <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                    <Routes>
                      <Route
                        index
                        element={<Navigate replace to="dashboard" />}
                      />
                      <Route path="dashboard" element={<Home />} />
                      <Route path="requesters" element={<Requester />} />
                      <Route path="logout" element={<LogoutPage />} />
                    </Routes>
                  </Box>
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
