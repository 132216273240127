import axios from "axios";
import { API_URL } from "../constants";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export function useTwoFA() {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminId, email } = location.state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    code: "",
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${API_URL}create/admin/verify-login-otp`,
        {
          adminId,
          otp: values.code,
        }
      );
      const { data, type, message } = response.data;
      if (type === "error") {
        enqueueSnackbar(message, {
          variant: type,
          persist: true,
        });
        setIsSubmitting(false);
      }
      if (type === "success") {
        enqueueSnackbar(message, {
          variant: type,
        });
        localStorage.setItem("pqckleToken", data.token);
        localStorage.setItem("pqckleAdmin", JSON.stringify(data.loggedUser));
        // Redirect to the dashboard or another page after successful verification
        navigate("/app/dashboard");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      enqueueSnackbar("OTP verification failed. Please try again.", {
        variant: "error",
      });
      setIsSubmitting(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axios.post(`${API_URL}create/admin/resend-otp`, {
        email,
      });
      const { type, message } = response.data;
      enqueueSnackbar(message, {
        variant: type,
      });
    } catch (error) {
      console.error("Error during OTP resend:", error);
      enqueueSnackbar("Failed to resend OTP. Please try again.", {
        variant: "error",
      });
    }
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Required!"),
  });

  return {
    onSubmit,
    validationSchema,
    handleResendOTP,
    isSubmitting,
    initialValues,
  };
}
