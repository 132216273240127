import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import { useFormik } from "formik";
import { CustomButton, CustomTextField } from "../../components";
import { useTwoFA } from "../../hooks/useTwoFA";

export function FASetup() {
  const {
    handleResendOTP,
    initialValues,
    isSubmitting,
    onSubmit,
    validationSchema,
  } = useTwoFA();

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box sx={{ position: "absolute", right: "10px", top: 0 }}>
        <img style={{ width: "120px" }} src={logo} alt="Logo" />
      </Box>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 500,
              mb: 3,
              textAlign: "left",
            }}
          >
            Verify 2FA
          </Typography>
          <form onSubmit={handleSubmit}>
            <CustomTextField
              name="code"
              placeholder="Enter Your code"
              label="Code"
              id="code"
              type="number"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.code && !!errors.code}
              helperText={touched.code && errors.code}
            />
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: 500,
                textAlign: "right",
                mb: 2,
              }}
              variant="body2"
              onClick={handleResendOTP}
            >
              Didn&apos;t receive the code?{" "}
              <span style={{ fontWeight: "bold" }}>Resend</span>
            </Typography>
            <CustomButton type="submit" disabled={isSubmitting}>
              Verify
            </CustomButton>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={login} alt="Login" style={{ width: "70%" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
