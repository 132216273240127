import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainHeading } from "../components";
import DynamicTable from "../components/DynamicTable";
import usePagination from "@mui/material/usePagination/usePagination";
import { $crud } from "../utils/CrudFactory";

export function Requester() {
  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const [filtrationData, setFiltrationData] = useState({
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
    blockStatus: "",
  });
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/requesters");
    return data;
  }

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    retrieveData().then((res) => {
      console.log(res.requesters);
      setRows(res.requesters);
    });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [filtrationData]);

  const getFilteredData = (data) => {
    if (!data.blockStatus) data.blockStatus = "";
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
    setPage(0);
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "Blocked":
        return {
          background: "rgba(254, 133, 106, 0.2)",
          color: "rgba(254, 133, 106, 1)",
          fontSize: "12px",
        };
      case "Active":
        return {
          background: "rgba(7, 79, 36, 0.2)",
          color: "rgba(7, 79, 36, 1)",
          fontSize: "12px",
        };
      default:
        return {
          background: "#FFF0ED",
          color: "rgba(254, 133, 106, 1)",
        };
    }
  };

  const statusOptions = ["Blocked", "Active"];

  const getStatusText = (blockStatus) => (blockStatus ? "Blocked" : "Active");

  const clientColumns = [
    {
      id: "requesterId",
      label: "Requester Id",
      align: "center",
    },
    { id: "name", label: "Requester Name", align: "center" },
    {
      id: "email",
      label: "Email",
      align: "center",
    },
    { id: "phone", label: "Phone", align: "center" },
    {
      id: "blockStatus",
      label: "Block Status",
      align: "center",
      renderCell: (row) => {
        const activeStatus = getStatusText(row.blockStatus);
        const styles = getStatusStyles(activeStatus);
        return (
          <Select
            value={activeStatus}
            // onChange={(e) =>
            //   handleStatusChange(row.orderID, "currentStatus", e.target.value)
            // }
            sx={{
              borderColor: styles.background,
              background: styles.background,
              color: styles.color,
              border: "none",
              outline: "none !important", // Remove outline
              fontSize: "12px",
              borderRadius: 0,
              "& .MuiSelect-icon": {
                color: styles.color, // Hide the down arrow icon
              },
              "&:hover": {
                outline: styles.background, // Remove hover background
              },
              "&:focus": {
                outline: styles.background, // Remove focus background
              },
              "& .MuiSelect-select": {
                paddingRight: "32px !important",
                padding: "8px 10px",
                width: "50px",
              },
            }}
            // onChange={(e) => handleStatusChange(row, e.target.value)}
          >
            {statusOptions.map((status) => (
              <MenuItem
                key={status}
                value={status}
                sx={getStatusStyles(status)}
              >
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <MainHeading text="Requesters" />
        </Grid>
      </Grid>
      <DynamicTable
        rows={rows}
        type="requesters"
        url="requesters"
        setRows={setRows}
        filterConfig={{}} // Pass the filter checkbox options
        noRecordTitle="No requesters yet!" // no data title
        onFilterData={getFilteredData} // filteration data
        onResetFilters={() => {
          setFiltrationData((prev) => ({
            from: "",
            to: "",
            amountTo: "",
            amountFrom: "",
          }));
        }}
        onSearchData={(value) =>
          setFiltrationData({ ...filtrationData, search: value })
        }
        paginationProps={{ limit, page, setLimit, setPage, total }}
        columns={clientColumns} // Pass the columns prop
      />
    </>
  );
}
