import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // const isAuthenticated = localStorage.getItem("pqckleAdmin") !== null;
  // return isAuthenticated ? children : <Navigate to="/" />;
  return children;
};

export default ProtectedRoute;
