import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import { useFormik } from "formik";
import { CustomButton, CustomTextField } from "../../components";
import { useResetPassword } from "../../hooks/useResetPassword";

export function ResetPassword() {
  const { initialValues, isSubmitting, onSubmit, validationSchema } =
    useResetPassword();

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Box sx={{ position: "absolute", right: "10px", top: 0 }}>
        <img style={{ width: "120px" }} src={logo} alt="Logo" />
      </Box>
      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 500,
              mb: 3,
              textAlign: "left",
            }}
          >
            Reset Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <CustomTextField
              name="otp"
              placeholder="Enter OTP"
              label="OTP"
              id="otp"
              type="password"
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.otp && !!errors.otp}
              helperText={touched.otp && errors.otp}
            />
            <CustomTextField
              name="newPassword"
              placeholder="Enter New Password"
              label="New Password"
              id="newPassword"
              type="password"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.newPassword && !!errors.newPassword}
              helperText={touched.newPassword && errors.newPassword}
            />
            <CustomButton type="submit" disabled={isSubmitting}>
              Reset Password
            </CustomButton>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        sx={{
          display: { xs: "none", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "16px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={login} alt="Login" style={{ width: "70%" }} />
        </Box>
      </Grid>
    </Grid>
  );
}
