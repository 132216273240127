import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SearchOutlined } from "@mui/icons-material";
import { BASE_URL } from "../constants";
import { IoIosSearch } from "react-icons/io";
import NewSidebar from "./SlidingBar";

export function Header() {
  // const [side, setSide] = useState(false);
  const user = JSON.parse(localStorage.getItem("pqckleAdmin"));
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <header className=" sticky bg-white z-50 top-2 mt-2 justify-between items-center rounded-xl px-10 flex h-20 w-full">
      <div className="flex items-center gap-2">
        {/* <button
          className="md:hidden block"
          onClick={() => {
            setSide(true);
          }}
        >
          slide
        </button> */}
        <div className="relative w-52 sm:w-72 md:w-[500px]">
          <input
            type="text"
            placeholder="Search here..."
            className="border h-12 px-3 pb-1 pr-10 w-full rounded-lg"
          />
          <IoIosSearch className="w-6 h-6 absolute top-3 right-5" />
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <p>Hello</p>
        <Avatar />
      </div>

      {/* <div
        className={`inset-0 fixed z-50 ${
          !side && "-translate-x-full"
        } transition-transform duration-300 bg-slate-200`}
      > 
        <NewSidebar />
      </div> */}
    </header>
  );
}
