import React from "react";
import PropTypes from "prop-types";

Tableloader.propTypes = {
  length: PropTypes.number.isRequired,
};

function Tableloader({ length }) {
  return (
    <>
      <tr className="h-12">
        {Array.from({ length: length + 1 }, (_, i) => {
          return (
            <td key={i} className=" px-6 py-4">
              <p className="animate-pulse w-12 h-4 rounded-xl bg-stone-200"></p>
            </td>
          );
        })}
      </tr>
      <tr className="h-12">
        {Array.from({ length: length + 1 }, (_, i) => {
          return (
            <td key={i} className=" px-6 py-4">
              <p className="animate-pulse animate-delay-200 w-12 h-4 rounded-xl bg-stone-200"></p>
            </td>
          );
        })}
      </tr>
      <tr className="h-12">
        {Array.from({ length: length + 1 }, (_, i) => {
          return (
            <td key={i} className=" px-6 py-4">
              <p className="animate-pulse animate-delay-[400ms] w-12 h-4 rounded-xl bg-stone-200"></p>
            </td>
          );
        })}
      </tr>
      <tr className="h-12">
        {Array.from({ length: length + 1 }, (_, i) => {
          return (
            <td key={i} className="px-6 py-10">
              <p className="animate-pulse animate-delay-[800ms] w-12 h-4 rounded-xl bg-stone-200"></p>
            </td>
          );
        })}
      </tr>
    </>
  );
}

export default Tableloader;
