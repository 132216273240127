import React from "react";
import { Typography } from "@mui/material";

export function MainHeading({ text }) {
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: 700,
        fontSize: "30px",
        // color: "#673C8E",
      }}
    >
      {text}
    </Typography>
  );
}
